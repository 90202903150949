<template>
  <v-container>
    <Submitting :value="submitting || loading" />
    <h1 class="text-h3 mt-4">Edit Opening Hours</h1>
    <h2 class="text-h5 mb-1">{{ restaurantName }}</h2>
    <v-form @reset="resetRestaurantData" @submit.prevent="submit">
      <v-simple-table>
        <template #default>
          <tbody>
            <tr v-for="(day, n) in businessHours.hours" :key="day.name">
              <td v-text="day.name" />
              <td>
                <div><v-switch @change="resetOpenTime(n, $event)" v-model="day.isOpen" :label="day.isOpen ? 'Open' : 'Closed'"/></div>
              </td>
              <td>
                <TimePicker v-model="day.openingTime" :disabled="!day.isOpen" label="Opening Time" />
              </td>
              <td>
                <TimePicker v-model="day.closingTime" :disabled="!day.isOpen" label="Closing Time" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-textarea v-model="businessHours.notes" outlined label="Additional notes" />

      <v-btn @click.prevent="goBack"><v-icon left>mdi-arrow-left</v-icon>Back</v-btn>
      <v-btn class="mx-3" @click.prevent="resetRestaurantData"><v-icon left>mdi-undo-variant</v-icon>Reset</v-btn>
      <v-btn color="primary" type="submit" :loading="submitting" :disabled="submitting" large>
        <v-icon left>mdi-check</v-icon>Save
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import firebase from '@/plugins/firebase'
import Submitting from '@/components/Submitting'
import TimePicker from '@/components/TextboxTimepicker.vue'

export default {
  name: 'BusinessHourEditor',

  props: {
    'restaurant-id': {
      type: String,
      required: true
    }
  },

  components: {
    Submitting, TimePicker
  },

  computed: {
    docPath () {
      return `/restaurants/${this.restaurantId}`
    },
    user () {
      return this.$store.getters['auth/user']
    }
  },

  data () {
    return ({
      submitting: false,
      loading: false,

      restaurantName: null,

      businessHours: [
        {
          name: 'Sunday',
          isOpen: false,
          openingTime: null,
          closingTime: null
        },
        {
          name: 'Monday',
          isOpen: false,
          openingTime: null,
          closingTime: null
        },
        {
          name: 'Tuesday',
          isOpen: false,
          openingTime: null,
          closingTime: null
        },
        {
          name: 'Wednesday',
          isOpen: false,
          openingTime: null,
          closingTime: null
        },
        {
          name: 'Thursday',
          isOpen: false,
          openingTime: null,
          closingTime: null
        },
        {
          name: 'Friday',
          isOpen: false,
          openingTime: null,
          closingTime: null
        },
        {
          name: 'Saturday',
          isOpen: false,
          openingTime: null,
          closingTime: null
        }
      ],
      notes: ''
    })
  },

  methods: {
    getRestaurantData () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(this.docPath).get()
          .then(doc => { resolve(doc.data()) })
          .catch(e => { reject(e) })
      })
    },
    saveToDatabase () {
      return new Promise((resolve, reject) => {
        firebase.firestore().doc(this.docPath).set({
          businessHours: this.$data.businessHours,
          author: this.user.uid
        }, { merge: true })
          .then(() => { resolve() })
          .catch(e => { reject(e) })
      })
    },
    async resetRestaurantData () {
      console.log('reset')
      this.$data.loading = true
      try {
        var restaurantData = await this.getRestaurantData()
        console.log(restaurantData)
        this.$data.restaurantName = restaurantData.name
        this.$data.businessHours = restaurantData.businessHours
      } catch (e) {
        console.error(e)
        this.$store.commit('toast/setError', 'Failed getting restaurant data')
      }

      this.$data.loading = false
    },
    async submit () {
      this.$data.submitting = true
      try {
        await this.saveToDatabase()
        this.$store.commit('toast/setNotification', { color: 'success', content: 'Successfully updated business hour.' })
      } catch (e) {
        console.error(e)
        this.$store.commit('toast/setError', 'Failed to update business hour.')
      }
      this.$data.submitting = false
    },
    goBack () {
      this.$router.go(-1)
    }
  },

  async mounted () {
    this.$data.loading = true
    try {
      var restaurantData = await this.getRestaurantData()
      this.$data.restaurantName = restaurantData.name
      this.$data.businessHours = restaurantData.businessHours
    } catch (e) {
      console.error(e)
      this.$store.commit('toast/setError', 'Failed getting restaurant data')
    }
    this.$data.loading = false
  }
}
</script>
